import React, { useState } from 'react';
import { Form, Button, Container, Row, Col, Alert, Card } from 'react-bootstrap';
import axios from 'axios';

const Login = ({ setToken }) => {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState('');

    const handleSubmit = async (event) => {
        event.preventDefault();
        try {
            const response = await axios.post('https://api.toptanapp.online/api/token', {
                username,
                password
            });
            setToken(response.data); // Access ve refresh token'ı ayarlayın
        } catch (err) {
            setError('Geçersiz kullanıcı adı veya şifre');
        }
    };

    return (
        <div style={{
            backgroundImage: 'url(https://source.unsplash.com/random)',
            backgroundSize: 'cover',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            padding: '1px'
        }}>
            <Container>
                <Row className="justify-content-center">
                    <Col md={12}>
                        <Card className="p-4" style={{ backgroundColor: 'rgba(255, 255, 255, 0.9)', borderRadius: '15px', boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)' }}>
                            <Card.Body>
                                <h2 className="mb-1 text-center" style={{ fontWeight: '300' }}>Giriş Yap</h2>
                                {error && <Alert variant="danger">{error}</Alert>}
                                <Form onSubmit={handleSubmit}>
                                    <Form.Group controlId="formUsername">
                                        <Form.Label>Kullanıcı Adı</Form.Label>
                                        <Form.Control
                                            type="text"
                                            placeholder="Kullanıcı adınızı girin"
                                            value={username}
                                            onChange={(e) => setUsername(e.target.value)}
                                        />
                                    </Form.Group>
                                    <Form.Group controlId="formPassword">
                                        <Form.Label>Şifre</Form.Label>
                                        <Form.Control
                                            type="password"
                                            placeholder="Şifrenizi girin"
                                            value={password}
                                            onChange={(e) => setPassword(e.target.value)}
                                        />
                                    </Form.Group>
                                    <Button variant="primary" type="submit" className="w-100 mt-3">
                                        Giriş Yap
                                    </Button>
                                </Form>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </div>
    );
};

export default Login;
