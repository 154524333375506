import React, { useState, useEffect, useImperativeHandle, forwardRef } from 'react';
import { Table, Alert, Spinner, Container, Card, Button, FormControl } from 'react-bootstrap';
import axios from 'axios';
import * as XLSX from 'xlsx';
import './PastPromotions.css';

const PastPromotions = forwardRef((props, ref) => {
    const [promotions, setPromotions] = useState([]);
    const [error, setError] = useState('');
    const [loading, setLoading] = useState(true);
    const [search, setSearch] = useState('');

    const getToken = () => JSON.parse(localStorage.getItem('token'));

    const fetchPastPromotions = async () => {
        try {
            const token = getToken();
            const response = await axios.get('https://api.toptanapp.online/api/promotion/user-promotions', {
                headers: {
                    Authorization: `Bearer ${token.access}`
                }
            });
            setPromotions(response.data);
        } catch (error) {
            console.error('Error fetching past promotions:', error);
            setError('Geçmiş promosyonlar alınırken bir hata oluştu.');
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchPastPromotions();
    }, []);

    useImperativeHandle(ref, () => ({
        refreshData() {
            setLoading(true);
            fetchPastPromotions();
        }
    }));

    const handleSearch = (e) => {
        setSearch(e.target.value);
    };

    const filteredPromotions = promotions.filter((promo) =>
        promo.promotion_code.toLowerCase().includes(search.toLowerCase()) ||
        promo.email_user_email.toLowerCase().includes(search.toLowerCase())
    );

    const handleExport = () => {
        const exportData = filteredPromotions.map((promo, index) => ({
            '#': index + 1,
            'Kodu': promo.promotion_code,
            'Mail': promo.email_user_email,
            'PASS': promo.email_user_password,
            'Oluşturulma': new Date(promo.created_at).toLocaleString(),
            'Kullanıldı Mı?': promo.used ? 'Evet' : 'Hayır',
            'Tarihi': new Date(promo.updated_at).toLocaleString()
        }));

        const worksheet = XLSX.utils.json_to_sheet(exportData);
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, 'Promotions');
        XLSX.writeFile(workbook, 'promotions.xlsx');
    };

    return (
        <Container fluid>
            <Card className="p-1 shadow-lg" style={{ borderRadius: '15px' }}>
                <Card.Body>
                    <h2 className="mb-4 text-center" style={{ fontWeight: '300' }}>Geçmiş Çekilen Promosyonlar</h2>
                    {error && <Alert variant="danger">{error}</Alert>}
                    {loading ? (
                        <div className="text-center">
                            <Spinner animation="border" />
                        </div>
                    ) : (
                        <>
                            <div className="d-flex justify-content-between mb-3">
                                <FormControl
                                    value={search}
                                    onChange={handleSearch}
                                    placeholder="Ara..."
                                    style={{ maxWidth: '300px' }}
                                />
                                <Button onClick={handleExport} className="btn btn-primary">
                                    Excel olarak indir
                                </Button>
                            </div>
                            <Table striped bordered hover responsive className="table-light table-striped">
                                <thead>
                                <tr>
                                    <th>#</th>
                                    <th>Kodu</th>
                                    <th>Mail</th>
                                    <th>PASS</th>
                                    <th>Oluşturulma</th>
                                    <th>Kullanıldı Mı?</th>
                                    <th>Tarihi</th>
                                </tr>
                                </thead>
                                <tbody>
                                {filteredPromotions.map((promo, index) => (
                                    <tr key={promo.id}>
                                        <td>{index + 1}</td>
                                        <td>{promo.promotion_code}</td>
                                        <td>{promo.email_user_email}</td>
                                        <td>{promo.email_user_password}</td>
                                        <td>{new Date(promo.created_at).toLocaleString()}</td>
                                        <td>{promo.used ? 'Evet' : 'Hayır'}</td>
                                        <td>{new Date(promo.updated_at).toLocaleString()}</td>
                                    </tr>
                                ))}
                                </tbody>
                            </Table>
                        </>
                    )}
                </Card.Body>
            </Card>
        </Container>
    );
});

export default PastPromotions;
