import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Container, Row, Col, Card, Spinner, ProgressBar } from 'react-bootstrap';

const StatisticsComponent = ({ token }) => {
    const [statistics, setStatistics] = useState({
        total_accounts: 0,
        inactive_accounts: 0,
        used_accounts: 0,
        total_promotions: 0,
        active_promotions: 0,
        inactive_promotions: 0,
    });
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.get('https://api.toptanapp.online/api/emails/user-statistics', {
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                });
                const data = response.data;
                if (
                    data.total_accounts !== 0 ||
                    data.inactive_accounts !== 0 ||
                    data.used_accounts !== 0 ||
                    data.total_promotions !== 0 ||
                    data.active_promotions !== 0 ||
                    data.inactive_promotions !== 0
                ) {
                    setStatistics(data);
                }
                setLoading(false);
            } catch (error) {
                console.error("Veri çekme hatası:", error);
                setLoading(false);
            }
        };

        fetchData();
        const interval = setInterval(fetchData, 3000); // 3 saniyede bir veri çek

        return () => clearInterval(interval); // Bileşen unmount olduğunda interval'ı temizle
    }, [token]);

    if (loading) {
        return (
            <Container className="d-flex justify-content-center align-items-center" style={{ height: '100vh' }}>
                <Spinner animation="border" />
            </Container>
        );
    }

    if (
        statistics.total_accounts === 0 &&
        statistics.inactive_accounts === 0 &&
        statistics.used_accounts === 0 &&
        statistics.total_promotions === 0 &&
        statistics.active_promotions === 0 &&
        statistics.inactive_promotions === 0
    ) {
        return null;
    }

    const scannedPercentage = statistics.total_accounts > 0
        ? (statistics.used_accounts / statistics.total_accounts) * 100
        : 0;

    return (
        <Container>
            <Card>
                <Row className="mt-4">
                    <Col>
                        <Card>
                            <Card.Body>
                                <Card.Title>Toplam Hesaplar</Card.Title>
                                <Card.Text>{statistics.total_accounts}</Card.Text>
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col>
                        <Card>
                            <Card.Body>
                                <Card.Title>Taranmış Hesaplar</Card.Title>
                                <Card.Text>{statistics.used_accounts}</Card.Text>
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col>
                        <Card>
                            <Card.Body>
                                <Card.Title>Aktif Olmayan Hesaplar</Card.Title>
                                <Card.Text>{statistics.inactive_accounts}</Card.Text>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
                <Row className="mt-4">
                    <Col>
                        <Card>
                            <Card.Body>
                                <Card.Title>Toplam Promosyonlar</Card.Title>
                                <Card.Text>{statistics.total_promotions}</Card.Text>
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col>
                        <Card>
                            <Card.Body>
                                <Card.Title>Aktif Promosyonlar</Card.Title>
                                <Card.Text>{statistics.active_promotions}</Card.Text>
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col>
                        <Card>
                            <Card.Body>
                                <Card.Title>Aktif Olmayan Promosyonlar</Card.Title>
                                <Card.Text>{statistics.inactive_promotions}</Card.Text>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
                <Row className="mt-4">
                    <Col>
                        <Card>
                            <Card.Body>
                                <Card.Title>Taranma Oranı</Card.Title>
                                <ProgressBar now={scannedPercentage} label={`${scannedPercentage.toFixed(2)}%`} />
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </Card>
        </Container>
    );
};

export default StatisticsComponent;
