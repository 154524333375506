import React, { useState, useEffect } from 'react';
import Login from './Login';
import Promotion from './Promotion';
import { Container, Button, Collapse, Card } from 'react-bootstrap';
import StatsDashboard from "./StatsDashboard";
import axios from 'axios';
import RecentUsers from "./RecentUsers";
import AccountRates from "./AccountRates";
import DailyPromotionStats from "./DailyPromotionStats";
import StatisticsComponent from "./StatisticsComponent";

function App() {
    const [token, setToken] = useState(null);
    const [isLoading, setIsLoading] = useState(true);

    const [statsDashboardOpen, setStatsDashboardOpen] = useState(false);
    const [accountRatesOpen, setAccountRatesOpen] = useState(false);
    const [dailyPromotionStatsOpen, setDailyPromotionStatsOpen] = useState(false);

    useEffect(() => {
        const savedToken = localStorage.getItem('token');
        if (savedToken) {
            const parsedToken = JSON.parse(savedToken);
            const now = Math.floor(Date.now() / 1000);
            if (parsedToken.access && parsedToken.refresh) {
                if (parsedToken.exp > now) {
                    setToken(parsedToken.access);
                    setIsLoading(false);
                } else {
                    // Token süresi dolmuş, yenile
                    refreshToken(parsedToken.refresh);
                }
            } else {
                setIsLoading(false);
            }
        } else {
            setIsLoading(false);
        }
    }, []);

    const refreshToken = async (refreshToken) => {
        try {
            const response = await axios.post('https://api.toptanapp.online/api/token/refresh', {
                refresh: refreshToken
            });
            const newToken = response.data;
            const newTokenWithRefresh = {
                access: newToken.access,
                refresh: refreshToken,
                exp: Math.floor(Date.now() / 1000) + 3600 // 1 saat sonra süresi dolacak
            };
            localStorage.setItem('token', JSON.stringify(newTokenWithRefresh));
            setToken(newToken.access);
        } catch (error) {
            console.error('Error refreshing token:', error);
            localStorage.removeItem('token');
            setToken(null);
        } finally {
            setIsLoading(false);
        }
    };

    const handleSetToken = (newToken) => {
        const newTokenWithExp = {
            ...newToken,
            exp: Math.floor(Date.now() / 1000) + 3600 // 1 saat sonra süresi dolacak
        };
        localStorage.setItem('token', JSON.stringify(newTokenWithExp));
        setToken(newToken.access);
    };

    if (isLoading) {
        return <div>Yükleniyor...</div>;
    }

    return (
        <Container>
            <RecentUsers />
            <Card>
                <StatsDashboard/>
            </Card>
            {token ? <StatisticsComponent token={token}/> : ''}
            {token ? <Promotion token={token} /> : <Login setToken={handleSetToken} />}
            <Card>
                <Card.Header>
                    <Button
                        variant="primary"
                        onClick={() => setAccountRatesOpen(!accountRatesOpen)}
                        aria-controls="account-rates-collapse"
                        aria-expanded={accountRatesOpen}
                    >
                        Hesap Oranları
                    </Button>
                </Card.Header>
                <Collapse in={accountRatesOpen}>
                    <div id="account-rates-collapse">
                        <Card.Body>
                            <AccountRates />
                        </Card.Body>
                    </div>
                </Collapse>
            </Card>

            <Card>
                <Card.Header>
                    <Button
                        variant="primary"
                        onClick={() => setDailyPromotionStatsOpen(!dailyPromotionStatsOpen)}
                        aria-controls="daily-promotion-stats-collapse"
                        aria-expanded={dailyPromotionStatsOpen}
                    >
                        Günlük Promosyon İstatistikleri
                    </Button>
                </Card.Header>
                <Collapse in={dailyPromotionStatsOpen}>
                    <div id="daily-promotion-stats-collapse">
                        <Card.Body>
                            <DailyPromotionStats />
                        </Card.Body>
                    </div>
                </Collapse>
            </Card>


        </Container>
    );
}

export default App;
