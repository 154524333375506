import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Container, Row, Col, Card } from 'react-bootstrap';
import './StatsDashboard.css';

const StatsDashboard = ({ token }) => {
    const [stats, setStats] = useState({
        total_users: 0,
        active_users: 0,
        hepsiburada_active: 0,
        yemeksepeti_active: 0,
        migros_active: 0,
        a11_active: 0,
        sok_active: 0,
        trendyol_active: 0
    });

    const [promotionStats, setPromotionStats] = useState([]);

    useEffect(() => {
        fetchStats();
        fetchPromotionStats();
        const interval = setInterval(() => {
            fetchStats();
            fetchPromotionStats();
        }, 3000); // 3 saniyede bir yenile
        return () => clearInterval(interval);
    }, []);

    const fetchStats = () => {
        axios.get('https://api.toptanapp.online/api/emails/email-users/stats')
            .then(response => {
                setStats(prevStats => {
                    const newStats = response.data;
                    for (let key in newStats) {
                        if (newStats[key] !== prevStats[key]) {
                            const element = document.getElementById(key);
                            element.classList.add('change');
                            setTimeout(() => {
                                element.classList.remove('change');
                            }, 1000);
                        }
                    }
                    return newStats;
                });
            })
            .catch(error => {
                console.error('Error fetching stats:', error);
            });
    };

    const fetchPromotionStats = () => {
        axios.get('https://api.toptanapp.online/api/promotion/promotion-statistics')
            .then(response => {
                setPromotionStats(response.data);
            })
            .catch(error => {
                console.error('Error fetching promotion stats:', error);
            });
    };

    const renderCard = (title, value, key) => (
        <Col xs={12} sm={6} md={3} key={key}>
            <Card className="text-center stats-card">
                <Card.Body>
                    <Card.Title>{title}</Card.Title>
                    <Card.Text id={key}>{value.toLocaleString()}</Card.Text>
                </Card.Body>
            </Card>
        </Col>
    );

    const renderPromotionCard = (k_type, count, promotion_code, key, cols) => (
        <Col xs={12} sm={12 / cols} md={12 / cols} key={key}>
            <Card className="text-center stats-card">
                <Card.Body>
                    <Card.Title>{promotion_code}</Card.Title>
                    <Card.Text>
                        Adet: {count.toLocaleString()}<br/>
                        Kod: {k_type}
                    </Card.Text>
                </Card.Body>
            </Card>
        </Col>
    );

    const renderPromotionCards = () => {
        const rows = [];
        let cols = promotionStats.length === 1 ? 1 : (promotionStats.length === 2 ? 2 : (promotionStats.length === 3 ? 3 : 4));
        for (let i = 0; i < promotionStats.length; i += cols) {
            const rowItems = promotionStats.slice(i, i + cols).map((stat, index) =>
                renderPromotionCard(stat.k_type, stat.count, stat.promotion_code, i + index, cols)
            );
            rows.push(<Row className="mt-2" key={i}>{rowItems}</Row>);
        }
        return rows;
    };

    return (
        <Container fluid>
            <div className="header">Canlı Veriler</div>
            <Row className="mt-2">
                {renderCard('Toplam Kullanıcı', stats.total_users, 'total_users')}
                {renderCard('Aktif Kullanıcılar', stats.active_users, 'active_users')}
                {renderCard('Hepsiburada Aktif', stats.hepsiburada_active, 'hepsiburada_active')}
                {renderCard('Yemeksepeti Aktif', stats.yemeksepeti_active, 'yemeksepeti_active')}
            </Row>
            <Row className="mt-2">
                {renderCard('Migros Aktif', stats.migros_active, 'migros_active')}
                {renderCard('A101 Aktif', stats.a11_active, 'a11_active')}
                {renderCard('Sok Aktif', stats.sok_active, 'sok_active')}
                {renderCard('Trendyol Aktif', stats.trendyol_active, 'trendyol_active')}
            </Row>
            {renderPromotionCards()}
        </Container>
    );
};

export default StatsDashboard;
