import React, { useState, useEffect, useRef } from 'react';
import { Button, Container, Row, Col, Card, Alert, ProgressBar, Form } from 'react-bootstrap';
import axios from 'axios';
import PastPromotions from './PastPromotions';
import NumberFetcher from "./NumberFetcher";

const Promotion = () => {
    const [promotion, setPromotion] = useState(JSON.parse(localStorage.getItem('promotion')) || null);
    const [error, setError] = useState('');
    const [fetching, setFetching] = useState(false);
    const [isPromotionFetched, setIsPromotionFetched] = useState(JSON.parse(localStorage.getItem('isPromotionFetched')) || false);
    const [canMarkAsUsed, setCanMarkAsUsed] = useState(false);
    const [timer, setTimer] = useState(calculateRemainingTime());
    const [totalQuota, setTotalQuota] = useState(0);
    const [remainingQuota, setRemainingQuota] = useState(0);
    const [selectedOption, setSelectedOption] = useState('');
    const [couponTypes, setCouponTypes] = useState([]);
    const pastPromotionsRef = useRef();

    const getToken = () => JSON.parse(localStorage.getItem('token'));

    function calculateRemainingTime() {
        const endTime = localStorage.getItem('endTime');
        if (!endTime || !isPromotionFetched) return 0;
        const currentTime = new Date().getTime();
        const remainingTime = (new Date(endTime).getTime() - currentTime) / 1000; // saniye cinsinden
        return Math.max(remainingTime, 0);
    }

    const refreshToken = async () => {
        try {
            const currentToken = getToken();
            const response = await axios.post('https://api.toptanapp.online/api/token/refresh', {
                refresh: currentToken.refresh
            });
            const newToken = response.data;
            const newTokenWithRefresh = {
                access: newToken.access,
                refresh: currentToken.refresh,
                exp: Math.floor(Date.now() / 1000) + 3600 // 1 saat sonra süresi dolacak
            };
            localStorage.setItem('token', JSON.stringify(newTokenWithRefresh));
            return newToken.access;
        } catch (error) {
            console.error('Error refreshing token:', error);
            localStorage.removeItem('token');
            setError('Oturum süresi doldu, lütfen tekrar giriş yapın.');
            return null;
        }
    };

    const getAccessToken = async () => {
        const currentToken = getToken();
        const now = Math.floor(Date.now() / 1000);
        if (currentToken.exp > now) {
            return currentToken.access;
        } else {
            return await refreshToken();
        }
    };

    useEffect(() => {
        let interval;
        if (isPromotionFetched && timer > 0) {
            interval = setInterval(() => {
                setTimer(prev => {
                    const newTime = prev - 1;
                    if (newTime <= 0) {
                        clearInterval(interval);
                        setCanMarkAsUsed(true);
                    }
                    return newTime;
                });
            }, 1000);
        } else if (timer === 0 && isPromotionFetched) {
            setCanMarkAsUsed(true);
        }
        return () => clearInterval(interval);
    }, [isPromotionFetched, timer]);

    useEffect(() => {
        localStorage.setItem('promotion', JSON.stringify(promotion));
        localStorage.setItem('isPromotionFetched', JSON.stringify(isPromotionFetched));
    }, [promotion, isPromotionFetched]);

    useEffect(() => {
        const fetchPromotionCodes = async () => {
            try {
                const response = await axios.get('https://api.toptanapp.online/api/promotion/promotion-statistics');
                const promotionData = response.data.map(promotion => ({
                    label: promotion.promotion_code,
                    value: promotion.k_type
                }));
                setCouponTypes(promotionData);
            } catch (error) {
                if (error.response && error.response.status === 403) {
                    setError(error.response.data.error);
                }else {
                    console.error('Error fetching promotion codes:', error);
                    setError('Promosyon kodları alınırken bir hata oluştu.');
                }
            }
        };
        fetchPromotionCodes();
    }, []);

    const fetchPromotion = async () => {
        if (!selectedOption) {
            setError('Lütfen bir seçenek seçiniz.');
            return;
        }
        try {
            setFetching(true);
            const token = await getAccessToken();
            if (!token) return;
            const response = await axios.get(`https://api.toptanapp.online/api/promotion/list?k_type=${selectedOption}`, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            });
            const data = response.data;
            setTotalQuota(data.total_quota);
            setRemainingQuota(data.remaining_quota);
            const activePromotions = data.promotions.filter(promo => promo.is_active);
            if (activePromotions.length > 0) {
                setPromotion(activePromotions[0]);
                setError('');
                setIsPromotionFetched(true);
                setTimer(20);
                const endTime = new Date(new Date().getTime() + 20000).toISOString();
                localStorage.setItem('endTime', endTime);
                setCanMarkAsUsed(false);
                // Geçmiş promosyonları yenile
                if (pastPromotionsRef.current) {
                    pastPromotionsRef.current.refreshData();
                }
            } else {
                setError('Aktif bir promosyon bulunamadı.');
            }
        } catch (error) {
            if (error.response && error.response.status === 403) {
                setError(error.response.data.error);
            }else {
                console.error('Error fetching promotion codes:', error);
                setError('Promosyon kodları alınırken bir hata oluştu.');
            }
        } finally {
            setFetching(false);
        }
    };

    const markAsUsed = async () => {
        if (!promotion) return;
        try {
            const token = await getAccessToken();
            if (!token) return;
            await axios.patch(`https://api.toptanapp.online/api/promotion/${promotion.id}/update`, {
                is_active: 0
            }, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            });
            setPromotion(null);
            setIsPromotionFetched(false);
            setError('');
            setTimer(0);
            localStorage.removeItem('promotion');
            localStorage.removeItem('isPromotionFetched');
            localStorage.removeItem('endTime');
            setCanMarkAsUsed(false);
            setSelectedOption(''); // Seçilen seçeneği sıfırla
            // Geçmiş promosyonları yenile
            if (pastPromotionsRef.current) {
                pastPromotionsRef.current.refreshData();
            }
        } catch (error) {
            console.error('Error updating promotion:', error);
            setError('Promosyon güncellenirken bir hata oluştu.');
        }
    };

    const copyToClipboard = (text) => {
        navigator.clipboard.writeText(text);
        alert('Panoya kopyalandı!');
    };

    const exportPromotions = async () => {
        try {
            const token = await getAccessToken();
            if (!token) {
                setError('Token alınamadı.');
                return;
            }
            const response = await axios.get('https://api.toptanapp.online/api/promotion/export', {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            });
            if (response.data.download_url) {
                const link = document.createElement('a');
                link.href = response.data.download_url;
                link.download = 'promotions_export.xlsx'; // İndirilen dosyanın adı
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
            } else {
                setError(response.data.detail || 'Ya iznin yok ya da hesabın yöneticiyle görüş.');
            }
        } catch (error) {
            if (error.response && error.response.data && error.response.data.detail) {
                setError(error.response.data.detail);
            } else {
                console.error('Error exporting promotions:', error);
                setError('Promosyonlar dışa aktarılırken bir hata oluştu.');
            }
        }
    };


    return (
        <Container fluid className="p-1" style={{ backgroundColor: '#f8f9fa', minHeight: '100vh' }}>
            <Row className="justify-content-center mt-3">
                <Col md={12}>
                    <Card className="p-4 shadow" style={{ borderRadius: '15px' }}>
                        <Card.Body>
                            <h2 className="mb-4 text-center" style={{ fontWeight: '300' }}>Promosyon Çek</h2>
                            {error && <Alert variant="danger">{error}</Alert>}
                            <div className="mb-3">
                                <h5>Kota Bilgisi</h5>
                                <ProgressBar now={(remainingQuota / totalQuota) * 100} label={`${remainingQuota}/${totalQuota}`} />
                            </div>
                            <Form>
                                <Form.Group controlId="couponTypeSelect" className="mb-4">
                                    <Form.Label style={{ fontWeight: 'bold' }}>Kupon Tipini Seçin</Form.Label>
                                    <Form.Control as="select" value={selectedOption} onChange={(e) => setSelectedOption(e.target.value)}>
                                        <option value="">Seçiniz</option>
                                        {couponTypes.map(coupon => (
                                            <option key={coupon.value} value={coupon.value}>{coupon.label}</option>
                                        ))}
                                    </Form.Control>
                                </Form.Group>
                            </Form>
                            <Button
                                onClick={fetchPromotion}
                                className="mb-3 w-100"
                                disabled={fetching || isPromotionFetched || !selectedOption}
                            >
                                Yeni Promosyon Getir
                            </Button>
                            <Button onClick={exportPromotions} className="w-100">
                                Kodları Toplu Excelle al Cookie dahil
                            </Button>
                            {promotion && (
                                <>
                                    <Card className="mb-3">
                                        <Card.Body>
                                            <Card.Title>Promosyon Kodu</Card.Title>
                                            <Card.Text>{promotion.promotion_code}</Card.Text>
                                        </Card.Body>
                                    </Card>
                                    <Card className="mb-3">
                                        <Card.Body>
                                            <Card.Title>Email</Card.Title>
                                            <Card.Text onClick={() => copyToClipboard(promotion.email_user_email)} style={{ cursor: 'pointer', color: 'blue' }}>
                                                {promotion.email_user_email}
                                            </Card.Text>
                                        </Card.Body>
                                    </Card>
                                    <Card className="mb-3">
                                        <Card.Body>
                                            <Card.Title>Şifre</Card.Title>
                                            <Card.Text onClick={() => copyToClipboard(promotion.email_user_password)} style={{ cursor: 'pointer', color: 'blue' }}>
                                                {promotion.email_user_password}
                                            </Card.Text>
                                        </Card.Body>
                                    </Card>
                                    <Card className="mb-3">
                                        <Card.Body>
                                            <Card.Title>JSONCookie</Card.Title>
                                            <Card.Text onClick={() => copyToClipboard(promotion.email_user_cookie)} style={{ cursor: 'pointer', color: 'blue' }}>
                                                {promotion.email_user_cookie}
                                            </Card.Text>
                                        </Card.Body>
                                    </Card>
                                    <Button
                                        onClick={markAsUsed}
                                        className="w-100"
                                        variant="danger"
                                        disabled={!canMarkAsUsed}
                                    >
                                        Kullandım {timer > 0 && `(${timer} saniye)`}
                                    </Button>
                                    <Button
                                        onClick={markAsUsed}
                                        className="w-100"
                                        variant="success"
                                        disabled={!canMarkAsUsed}
                                    >
                                        Sipariş Çektim {timer > 0 && `(${timer} saniye)`}
                                    </Button>
                                    <Card>
                                        <NumberFetcher/>
                                    </Card>
                                </>
                            )}
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
            <Row className="justify-content-center mt-5">
                <Col md={12}>
                    <PastPromotions ref={pastPromotionsRef} />
                </Col>
            </Row>

        </Container>
    );
};

export default Promotion;
