import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Card, ProgressBar, Table } from 'react-bootstrap';
import axios from 'axios';
import './DailyPromotionStats.css';

const DailyPromotionStats = () => {
    const [stats, setStats] = useState({
        daily_created_promotions: 0,
        daily_active_promotions: 0,
        daily_inactive_promotions: 0,
        k_type_statistics: []
    });

    useEffect(() => {
        fetchDailyStats();
        const interval = setInterval(fetchDailyStats, 3000); // 3 saniyede bir yenile
        return () => clearInterval(interval);
    }, []);

    const fetchDailyStats = () => {
        axios.get('https://api.toptanapp.online/api/promotion/daily-promotion-stats')
            .then(response => {
                setStats(response.data);
            })
            .catch(error => {
                console.error('Veriler alınırken hata oluştu:', error);
            });
    };

    const renderStatCard = (title, value, max, variant) => (
        <Col xs={12} sm={6} md={4} key={title}>
            <Card className="text-center stat-card">
                <Card.Body>
                    <Card.Title>{title}</Card.Title>
                    <Card.Text>{value}</Card.Text>
                    <ProgressBar now={(value / max) * 100} variant={variant} />
                </Card.Body>
            </Card>
        </Col>
    );

    return (
        <Container fluid className="p-3" style={{ backgroundColor: '#f8f9fa' }}>
            <Row className="justify-content-center mt-2">
                {renderStatCard('Günlük Toplam', stats.daily_created_promotions, 100, 'success')}
                {renderStatCard('Anlık Aktif', stats.daily_active_promotions, 100, 'info')}
                {renderStatCard('Günlük Kullanılmış', stats.daily_inactive_promotions, 100, 'warning')}
            </Row>
            <Row className="justify-content-center mt-4">
                <Col xs={12}>
                    <Card className="p-4 shadow-lg" style={{ borderRadius: '15px' }}>
                        <Card.Body>
                            <Table striped bordered hover responsive className="table-light table-striped">
                                <thead>
                                <tr>
                                    <th>#</th>
                                    <th>Promosyon Kodu (Günlük)</th>
                                    <th>Toplam</th>
                                    <th>Aktif</th>
                                    <th>Kullanıldı</th>
                                </tr>
                                </thead>
                                <tbody>
                                {stats.k_type_statistics.map((stat, index) => (
                                    <tr key={index}>
                                        <td>{index + 1}</td>
                                        <td>{stat.promotion_code}</td>
                                        <td>{stat.created_count}</td>
                                        <td>{stat.active_count}</td>
                                        <td>{stat.inactive_count}</td>
                                    </tr>
                                ))}
                                </tbody>
                            </Table>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </Container>
    );
};

export default DailyPromotionStats;
