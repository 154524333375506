import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Toast, ToastContainer, Badge } from 'react-bootstrap';
import './RecentUsers.css';

const RecentUsers = () => {
    const [recentUsers, setRecentUsers] = useState([]);
    const [showToast, setShowToast] = useState(false);
    const [timer, setTimer] = useState(0);

    useEffect(() => {
        fetchRecentUsers();
        const interval = setInterval(fetchRecentUsers, 6000); // 3 saniyede bir güncelle
        return () => clearInterval(interval);
    }, []);

    useEffect(() => {
        if (showToast && timer > 0) {
            const countdown = setInterval(() => {
                setTimer(prevTimer => prevTimer - 1);
            }, 1000);
            return () => clearInterval(countdown);
        } else if (timer === 0) {
            setShowToast(false);
        }
    }, [showToast, timer]);

    const fetchRecentUsers = () => {
        axios.get('https://api.toptanapp.online/api/promotion/recent-promotion-users')
            .then(response => {
                const users = response.data.recent_users;
                if (users.length > 0) {
                    setRecentUsers(users);
                    setShowToast(true);
                    setTimer(5); // Geri sayım süresi 5 saniye
                }
            })
            .catch(error => {
                console.error('Error fetching recent users:', error);
            });
    };

    return (
        <div>
            <ToastContainer position="bottom-end" className="p-3" style={{ zIndex: 1050 }}>
                <Toast show={showToast} onClose={() => setShowToast(false)}>
                    <Toast.Header>
                        <strong className="me-auto">Son Hesap Çekenler</strong>
                        <small>{timer > 0 ? `${timer} saniye` : ''}</small>
                    </Toast.Header>
                    <Toast.Body>
                        {recentUsers.map(user => (
                            <div key={user.username} className="recent-user-item">
                                <strong>{user.username}</strong>: <Badge bg="info">{user.promotion_code}</Badge>
                            </div>
                        ))}
                    </Toast.Body>
                </Toast>
            </ToastContainer>
        </div>
    );
};

export default RecentUsers;
