import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Card, ProgressBar } from 'react-bootstrap';
import axios from 'axios';
import './AccountRates.css';

const AccountRates = () => {
    const [accountRates, setAccountRates] = useState({
        created_count_last_hour: 0,
        average_creation_rate_per_hour: 0,
        updated_count_last_hour: 0,
        average_scan_rate_per_hour: 0
    });

    useEffect(() => {
        fetchAccountRates();
        const interval = setInterval(fetchAccountRates, 3000); // 3 saniyede bir yenile
        return () => clearInterval(interval);
    }, []);

    const fetchAccountRates = () => {
        axios.get('https://api.toptanapp.online/api/emails/account-rates')
            .then(response => {
                const data = response.data;
                // Verileri tam sayıya çevirme
                setAccountRates({
                    created_count_last_hour: Math.round(data.created_count_last_hour),
                    average_creation_rate_per_hour: Math.round(data.average_creation_rate_per_hour),
                    updated_count_last_hour: Math.round(data.updated_count_last_hour),
                    average_scan_rate_per_hour: Math.round(data.average_scan_rate_per_hour)
                });
            })
            .catch(error => {
                console.error('Veriler alınırken hata oluştu:', error);
            });
    };

    const renderStatCard = (title, value, max, variant) => (
        <Col xs={12} sm={6} md={3}>
            <Card className="text-center stat-card">
                <Card.Body>
                    <Card.Title>{title}</Card.Title>
                    <Card.Text>{value}</Card.Text>
                    <ProgressBar now={(value / max) * 100} variant={variant} />
                </Card.Body>
            </Card>
        </Col>
    );

    return (
        <Container fluid className="p-1" style={{ backgroundColor: '#f8f9fa' }}>
            <Row className="justify-content-center mt-2">
                {renderStatCard('Saatte Açılan', accountRates.created_count_last_hour, 1000, 'success')}
                {renderStatCard('Saat Başı Oran(A)', accountRates.average_creation_rate_per_hour, 1000, 'info')}
                {renderStatCard('Saatte Taranan', accountRates.updated_count_last_hour, 1000, 'warning')}
                {renderStatCard('Saat Başı Oran(T)', accountRates.average_scan_rate_per_hour, 1000, 'danger')}
            </Row>
        </Container>
    );
};

export default AccountRates;
