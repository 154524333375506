import React, { useState } from 'react';
import axios from 'axios';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Container, Card, Form, Button, Alert, Spinner } from 'react-bootstrap';

const NumberFetcher = () => {
    const [country, setCountry] = useState('');
    const [balance, setBalance] = useState(null);
    const [orderId, setOrderId] = useState(null);
    const [phone, setPhone] = useState('');
    const [smsCode, setSmsCode] = useState(null);
    const [loading, setLoading] = useState(false);
    const [smsLoading, setSmsLoading] = useState(false);
    const [error, setError] = useState(null);

    const countries = ['France', 'Italy', 'Belarus', 'Uruguay', 'Togo', 'Germany', 'Netherlands'];

    const handleFetchNumber = async () => {
        if (!country) {
            setError('Lütfen ülke seçin.');
            return;
        }

        setError(null);
        setLoading(true);
        setSmsCode(null);
        setPhone('');
        setBalance(null);
        setOrderId(null);

        try {
            const response = await axios.get(`https://no.toptanapp.online/getNumber?country=${country}`);
            const data = response.data;

            if (data.status) {
                setBalance(data.balance);
                setOrderId(data.orders[0].id);
                setPhone(data.orders[0].phone);
                setLoading(false);
                setSmsLoading(true);
                checkSmsCode(data.orders[0].id);
            } else {
                setError('Numara çekme işlemi başarısız oldu.');
                setLoading(false);
            }
        } catch (error) {
            setError('Numara çekerken bir hata oluştu.');
            setLoading(false);
        }
    };

    const checkSmsCode = async (id) => {
        let attempts = 0;
        const maxAttempts = 3;
        const delay = (ms) => new Promise(resolve => setTimeout(resolve, ms));

        while (attempts < maxAttempts) {
            try {
                await delay(20000); // 20 seconds delay
                const response = await axios.get(`https://no.toptanapp.online/getStatus/${id}`);
                const data = response.data;

                if (data.status && data.order.smsCode) {
                    setSmsCode(data.order.smsCode);
                    setError(null);
                    setSmsLoading(false);
                    return;
                }
            } catch (error) {
                setError('An error occurred while checking the SMS code');
            }

            attempts++;
        }

        setError('Failed to receive SMS code after multiple attempts');
        setSmsLoading(false);
    };

    return (
        <Container className="mt-5">
            <h1 className="text-center mb-4">Numara ve SMS işlemleri</h1>
            <Card className="p-4 shadow-sm">
                <Form>
                    <Form.Group className="mb-3">
                        <Form.Label>Ülke Seç</Form.Label>
                        <Form.Control
                            as="select"
                            value={country}
                            onChange={(e) => setCountry(e.target.value)}
                        >
                            <option value="">Ülke Seç</option>
                            {countries.map((country) => (
                                <option key={country} value={country}>{country}</option>
                            ))}
                        </Form.Control>
                    </Form.Group>
                    <Button variant="primary" className="w-100" onClick={handleFetchNumber} disabled={loading}>
                        {loading ? <Spinner animation="border" size="sm" /> : 'Numara Çek'}
                    </Button>
                </Form>
                {error && <Alert variant="danger" className="mt-3">{error}</Alert>}
                {loading && <Alert variant="info" className="mt-3"><Spinner animation="border" size="sm" /> Numara bekleniyor...</Alert>}
                {balance !== null && <Alert variant="info" className="mt-3">Balance: {balance}</Alert>}
                {phone && <Alert variant="info" className="mt-3">Phone: {phone}</Alert>}
                {orderId && <Alert variant="info" className="mt-3">Order ID: {orderId}</Alert>}
                {smsLoading && <Alert variant="info" className="mt-3"><Spinner animation="border" size="sm" /> SMS kontrolü yapılıyor...</Alert>}
                {smsCode && <Alert variant="success" className="mt-3">SMS Code: {smsCode}</Alert>}
            </Card>
        </Container>
    );
};

export default NumberFetcher;
